<!-- 版本管理 -->
<template>
  <a-table
    bordered
    rowKey="id"
    size="middle"
    :columns="columns"
    :data-source="source"
    :pagination="false"
    @change="handlerTableChange"
  >
    <template #op="{record}">
      <a-button type="primary" size="small" @click="editStar(record)">修改</a-button>
    </template>
  </a-table>
  <a-modal
    :title="title"
    v-model:visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    @cancel="handlerCancel"
  >
    <a-form ref="formRef" :model="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
      <a-form-item label="平台名称">
        <a-input v-model:value="form.type" />
      </a-form-item>
      <a-form-item label="内部版本">
        <a-input v-model:value="form.versionNumber" />
      </a-form-item>
      <a-form-item label="强制更新">
        <a-input v-model:value="form.requiredNumber" />
      </a-form-item>
      <a-form-item label="下载地址">
        <a-input v-model:value="form.downloadAddress" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import useVersionManage from "@/js/useVersionmanage";
export default {
  setup() {
    const {
      source,
      columns,
      pagination,
      title,
      visible,
      confirmLoading,
      form,
      fileList,
      optionArr,
      handlerTableChange,
      addNewGoodsType,
      editStar,
      formRef,
      handleOk,
      handlerCancel
    } = useVersionManage();
    return {
      source,
      columns,
      pagination,
      title,
      visible,
      confirmLoading,
      form,
      formRef,
      fileList,
      optionArr,
      handlerTableChange,
      addNewGoodsType,
      editStar,
      handleOk,
      handlerCancel
    };
  }
};
</script>

<style>
</style>
