import { ref, onMounted } from 'vue'
import { getAppVersionList, update } from '@/api/version'
import { message } from 'ant-design-vue'
import { stringify } from 'qs'
export default function useVersionManage() {
  // table
  const source = ref([])
  const columns = ref([
    {
      title: '平台名称',
      dataIndex: 'type',
      key: 'type',
      width: '200px'
    },
    {
      title: '内部版本',
      dataIndex: 'versionNumber',
      key: 'versionNumber'
    },
    {
      title: '强制更新版本',
      dataIndex: 'requiredNumber',
      key: 'requiredNumber'
    },
    {
      title: '下载地址',
      dataIndex: 'downloadAddress',
      key: 'downloadAddress'
    },
    {
      title: '操作',
      key: 'op',
      width: '200px',
      slots: { customRender: 'op' }
    }
  ])
  const pagination = ref({
    position: 'bottom',
    total: 200,
    defaultPageSize: 20,
    pageSizeOptions: ['10', '15', '20', '30'],
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: total => `共 ${total} 条`,
    hideOnSinglePage: true
  })
  const formRef = ref(null)
  const handleOk = async () => {
    formRef.value.validate().then(async (val) => {
      confirmLoading.value = true
      const { msg, status } = await update(stringify(form.value))
      console.log(msg, status)
      if (status === '200') {
        message.success(msg)
        confirmLoading.value = false
        visible.value = false
        getList()
      } else {
        message.error(msg)
        confirmLoading.value = false
      }
    })
  }
  const handlerTableChange = pagination => {
    const { current, pageSize } = pagination
    console.log(current, pageSize)
  }
  const getList = async function () {
    // eslint-disable-next-line no-unused-vars
    const { data, msg, status } = await getAppVersionList()

    if (status === '200') {
      const dataOnThisPage = data
      source.value = dataOnThisPage
      pagination.value = { ...pagination.value }
    } else {

    }
  }
  onMounted(getList)

  // modal
  const title = ref('编辑商品类型')
  const visible = ref(false)
  const confirmLoading = ref(false)
  const handlerCancel = () => {
    confirmLoading.value = false
    visible.value = false
    resetForm()
  }
  const addNewGoodsType = () => {
    title.value = '新建商品类型'
    visible.value = true
  }
  const editStar = (row) => {
    title.value = '编辑版本'
    visible.value = true
    form.value = { type: row.type, versionNumber: row.versionNumber, downloadAddress: row.downloadAddress, id: row.id, versionCode: row.versionCode, requiredNumber: row.requiredNumber }
  }

  // form
  const form = ref({
    name: '',
    icon: '',
    type: 1,
    ids: ''
  })
  const fileList = ref([])
  const optionArr = ref([
    { label: '帖子类星球', value: 1 },
    { label: '优惠类星球', value: 2 }
  ])
  const resetForm = () => {
    form.value = {
      name: '',
      icon: '',
      type: 1,
      ids: ''
    }
  }

  return {
    source,
    columns,
    pagination,
    handlerTableChange,
    formRef,
    title,
    visible,
    confirmLoading,
    handleOk,
    handlerCancel,
    addNewGoodsType,
    editStar,

    form,
    fileList,
    optionArr,
    resetForm
  }
}
